import "./App.css";

import React from "react";

// import Landing1 from "./pages/Landing1";
// import Landing2 from "./pages/Landing2";
import Landing3 from "./pages/Landing3";

function App() {
  return (
    <div className="App">
      <Landing3 />
    </div>
  );
}

export default App;
